/* eslint-disable import/no-unresolved */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import productServices from '../api/productServices';

export const initialState = {
  data: [],
  product: {},
  productsByCategory: [],
  searchProduct: [],
  loading: 'idle',
  error: null,
  message: null,
};

export const createProduct = createAsyncThunk('product/createProduct', async (data) => {
  try {
    const res = await productServices.createProduct(data);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const deleteProduct = createAsyncThunk('product/deleteProduct', async (id) => {
  try {
    const res = await productServices.deleteProduct(id);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getProduct = createAsyncThunk('product/getProduct', async () => {
  try {
    const res = await productServices.getProducts();

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const searchProduct = createAsyncThunk('product/searchProduct', async (data) => {
  try {
    const res = await productServices.searchProducts(data);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getProductsById = createAsyncThunk('product/getProductsById', async (id) => {
  try {
    const res = await productServices.getProductsById(id);
    console.log(res);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getProductsCategorey = createAsyncThunk('product/getProductsCategorey', async (id) => {
  try {
    const res = await productServices.getProductsByCategorey(id);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const editProducts = createAsyncThunk('product/editProducts', async (id) => {
  try {
    const res = await productServices.editProducts(id);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProduct.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createProduct.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(createProduct.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(getProduct.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.data = action.payload;
    });
    builder.addCase(getProduct.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(searchProduct.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(searchProduct.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.searchProduct = action.payload;
    });
    builder.addCase(searchProduct.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(getProductsById.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getProductsById.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.product = action.payload;
    });
    builder.addCase(getProductsById.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload.data;
    });

    builder.addCase(getProductsCategorey.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getProductsCategorey.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.productsByCategory = action.payload;
    });
    builder.addCase(getProductsCategorey.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(editProducts.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(editProducts.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(editProducts.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(deleteProduct.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(deleteProduct.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });
  },
});

export default productSlice.reducer;
