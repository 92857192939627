/* eslint-disable import/order */
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import './index.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCartTotal } from './Redux/slice/cartSlice';
import { getAllCategory } from './Redux/slice/categorySlice';
import { getProduct, getProductsCategorey } from './Redux/slice/products';
import { toast } from 'react-toastify';
import { addToAllProducts } from './Redux/slice/mainProductsSlice';

// ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const category = useSelector((s) => s.category?.data?.data);

  const getProductTeaTrolley = async () => {
    try {
      const res = await dispatch(getProductsCategorey('64e0630f9ef639e629e98591'));
      if (res.payload.success) {
        const { products } = res.payload;
        dispatch(addToAllProducts({ category: 'teaTrolley', products })); // Add to teaTrolley category
      }
      console.log('res', res);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getProductNestingTable = async () => {
    try {
      const res = await dispatch(getProductsCategorey('64e063009ef639e629e9858f'));
      if (res.payload.success) {
        const { products } = res.payload;
        dispatch(addToAllProducts({ category: 'nestingTable', products })); // Add to teaTrolley category
      }
      console.log('res', res);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getProductWallHanging = async () => {
    try {
      const res = await dispatch(getProductsCategorey('64e061fc9ef639e629e98574'));
      if (res.payload.success) {
        const { products } = res.payload;
        dispatch(addToAllProducts({ category: 'wallHanging', products })); // Add to teaTrolley category
      }
      console.log('res', res);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getProductsData = async () => {
    try {
      await dispatch(getProduct());
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getProductTeaTrolley();
    getProductNestingTable();
    getProductWallHanging();
    getProductsData();
    // dispatch(getProductsCategorey('64e0e08eb039f6c4e6d00f95'));
    // dispatch(getProductsCategorey('64e063009ef639e629e9858f'));
    // dispatch(getProductsCategorey('64e0623c9ef639e629e9857c'));
  }, [dispatch]);
  useEffect(() => {
    if (!category) {
      dispatch(getAllCategory());
    }
  }, []);

  useEffect(() => {
    dispatch(getCartTotal());
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
