/* eslint-disable prefer-const */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allProducts: { teaTrolley: [], nestingTable: [], wallHanging: [] },
};

const mainProductSlice = createSlice({
  name: 'mainProducts',
  initialState,
  reducers: {
    addToAllProducts(state, action) {
      const { category, products } = action.payload;
      state.allProducts[category] = products;
    },
  },
});

export const { addToAllProducts } = mainProductSlice.actions;

export default mainProductSlice.reducer;
