/* eslint-disable import/no-unresolved */
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import ProductCard from 'src/src/Shared/ProductCard';
import HeaderPrimary from 'src/src/Shared/SectionHeader/HeaderPrimary';
import Image from '../../assets/image.webp';

const AllProducts = () => {
  const products = useSelector((s) => s.products?.data?.data);

  return (
    <>
      {products?.length !== 0 && (
        <Stack py={4} spacing={2}>
          <HeaderPrimary title="PRODUCTS" />
          <Typography textAlign={'center'} fontSize={'12px'} fontWeight={400}>
            View All
          </Typography>
          <Box>
            <Container maxWidth="lg">
              <Grid rowSpacing={6} columnSpacing={2} container>
                {products?.map((item) => (
                  <Grid key={item?._id} item md={3} xs={12}>
                    <ProductCard categoreyId={item.categoryId} item={item} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Box>
        </Stack>
      )}
    </>
  );
};

export default AllProducts;
