/* eslint-disable import/no-unresolved */
import { Box, Button, Container, Divider, Grid, IconButton, Stack, TablePagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ProductCard from 'src/src/Shared/ProductCard';
import { toast } from 'react-toastify';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard2 from 'src/src/Shared/ProductCard2';
import { getProduct, getProductsCategorey } from 'src/Redux/slice/products';
import Image from '../../assets/image.webp';
import V2 from '../../assets/v2.png';
import V3 from '../../assets/v3.png';
import V4 from '../../assets/v4.png';
import H3 from '../../assets/h3o.png';

const ProductCatgorey = () => {
  const param = useParams();
  const [isLayoutChange, setIsLayoutChange] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState([]);
  const { state } = useLocation();
  // const products = useSelector((s) => s.products?.productsByCategory?.products);
  console.log('param', param.categorey);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  console.log(param.categorey.replace('-', ' '));
  const paragraph =
    'The perfect home decoration is what transforms the look of your surroundings. With the right items, showpieces, and other equipment, you can make your home visitor go wow. To ignite that feeling in your guests, HomeTrends offers a variety of amazingly designed traditional items to illuminate your home interior. We believe that every home can get a transformational look. All it needs is an item, which you get at HomeTrends. Present the tissues in appealing-looking tissue boxes to different types of trolleys, HomeTrends will exceed your expectations. Order your preferred home decoration equipment and have it at your doorstep in no time.';

  const lines = paragraph.split('. ');

  const [gridLayout, setGridLayout] = useState(3); // Initial grid layout is set to 3

  const getProductsByCategories = async () => {
    try {
      const res = await dispatch(getProductsCategorey(state.id));
      if (res.payload.success) {
        setProducts(res.payload.products);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getAllProducts = async () => {
    try {
      const res = await dispatch(getProduct());
      console.log('res++++++++', res);
      if (res.payload.success) {
        setProducts(res.payload.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (param.categorey === 'All-Products') {
      getAllProducts();
    } else {
      getProductsByCategories();
    }
  }, [dispatch, state.id]);

  const handleGridLayoutChange = (layout) => {
    setGridLayout(layout);
    setIsLayoutChange(false);
  };

  return (
    <Box py={6}>
      <Container maxWidth="lg">
        <Stack spacing={2}>
          <Stack spacing={1} direction={'row'}>
            <Link style={{ fontSize: '12px', fontWeight: 400, color: 'rgb(153, 153, 153)' }} to={'/'}>
              Home
            </Link>
            <Typography fontSize={'12px'} fontWeight={400} color={'rgb(153, 153, 153)'}>
              /
            </Typography>
            <Typography fontSize={'12px'} fontWeight={400} color={'rgb(153, 153, 153)'}>
              {param?.categorey?.replace('-', ' ')}
            </Typography>
          </Stack>
        </Stack>
        <Typography mt={2} fontSize={'20px'} fontWeight={700}>
          {param?.categorey?.replace('-', ' ')}
        </Typography>
        {/* <Stack spacing={1}>
          {lines.map((line, index) => (
            <Typography fontSize={'12px'} fontWeight={400} key={index}>
              {line}.
              <br />
            </Typography>
          ))}
        </Stack> */}
        <Stack spacing={4} py={4}>
          <Divider />
          <Box display={'flex'} justifyContent={'space-between'}>
            <Stack alignItems={'center'} spacing={3} direction={'row'}>
              <Typography>VIEW AS</Typography>
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <IconButton onClick={() => setIsLayoutChange(true)}>
                  <img width={'30px'} height={'30px'} src={H3} alt="" />
                </IconButton>
                <IconButton onClick={() => handleGridLayoutChange(6)}>
                  <img width={'30px'} height={'30px'} src={V2} alt="" />
                </IconButton>
                <IconButton onClick={() => handleGridLayoutChange(4)}>
                  <img width={'30px'} height={'30px'} src={V3} alt="" />
                </IconButton>
                <IconButton onClick={() => handleGridLayoutChange(3)}>
                  <img width={'20px'} height={'20px'} src={V4} alt="" />
                </IconButton>
              </Stack>
            </Stack>
            <TablePagination
              rowsPerPageOptions={[8, 12, 16]}
              component="div"
              count={products?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          {products?.length !== 0 ? (
            <Container overflow={'hidden'} maxWidth="lg">
              {!isLayoutChange ? (
                <Grid rowSpacing={6} columnSpacing={2} container>
                  {products?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <Grid key={item.id} item md={gridLayout} xs={12}>
                      <ProductCard allProducts={param.categorey} categoreyId={state.id} item={item} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid rowSpacing={6} columnSpacing={2} container overflow={'hidden'}>
                  {products?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                    <Grid key={item.id} item md={12} xs={12}>
                      <ProductCard2 allProducts={param.categorey} categoreyId={state.id} item={item} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Container>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40vh',
                border: '1px solid  gray',
                borderStyle: 'dashed',
              }}
            >
              <Typography textAlign={'center'}>No Data Found</Typography>
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default ProductCatgorey;
