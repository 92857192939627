import { callAPi, callAPiMultiPart } from './http-common';

const createProduct = (data) => callAPiMultiPart.post('/addProduct', data);
const getProducts = () => callAPi.get('/getProducts');
const editProducts = (data) => callAPi.patch('product/editProducts', data);
const getProductsById = (id) => callAPi.get(`getproductById/${id}`);
const getProductsByCategorey = (id) => callAPi.get(`getProductBysubCat/${id}`);
const searchProducts = (title) => callAPi.get(`searchProducts?title=${title}&limit=${4}`);
const deleteProduct = (id) => callAPi.delete(`deleteProduct/${id}`);

const productServices = {
  createProduct,
  getProducts,
  editProducts,
  getProductsById,
  getProductsByCategorey,
  searchProducts,
  deleteProduct,
};

export default productServices;
