import { callAPi, callAPiMultiPart } from './http-common';

const createOrder = (data) => callAPi.post('/buyProduct', data);
const getOrders = () => callAPi.get('/getAllOrders');
const editOrders = (data) => callAPi.patch('order/editOrders', data);

const orderServices = {
  createOrder,
  getOrders,
  editOrders,
};

export default orderServices;
