import { callAPi, callAPiMultiPart } from './http-common';

const createCetgory = (data) => callAPi.post('/createCetgory', data);
const createSubCetgory = (data) => callAPi.post('/sub/createCetgory', data);
const getAllCategory = () => callAPi.get('/getAllCategory');

const categoryServices = {
  createCetgory,
  createSubCetgory,
  getAllCategory,
};

export default categoryServices;
