/* eslint-disable import/no-unresolved */

import cartSlice from './cartSlice';
import products from './products';
import order from './orderSlice';
import category from './categorySlice';
import allProducts from './mainProductsSlice';

const rootReducer = {
  cartSlice,
  products,
  order,
  category,
  allProducts,
};

export default rootReducer;
