import { Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { API_IMG_URL } from '../Redux/api/http-common';

const ViewItemsOrder = () => {
  const { state } = useLocation();
  console.log('state', state.data);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        {state?.data?.map((item) => (
          <Grid item md={3} xs={12} key={item?._id}>
            <Stack spacing={2}>
              <Typography>Prodcut Price : {item?.price}</Typography>
              <Typography>Prodcut Quantity : {item?.quantity}</Typography>
              <img src={API_IMG_URL + item.imgUrl} alt="" />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ViewItemsOrder;
