/* eslint-disable import/no-unresolved */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import userServices from '../api/userServices';

export const initialState = {
  data: [],
  loading: 'idle',
  error: null,
  message: null,
};

export const login = createAsyncThunk('login/userLogin', async (data) => {
  try {
    const res = await userServices.loginUser(data);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(login.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });
  },
});

export default loginSlice.reducer;
