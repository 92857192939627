/* eslint-disable import/no-unresolved */
import { Box, Container } from '@mui/material';
import Slider from 'react-slick';
import SliderCard from 'src/src/Shared/SliderCard';

const ProductSlider = ({ productsCategorey, categoreyId, setRefetch, refetch }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container sx={{ margin: 'auto !important' }} maxWidth="lg">
      <Box m={'auto'}>
        <Slider {...settings}>
          {productsCategorey?.map((item) => (
            <div key={item._id}>
              <SliderCard refetch={refetch} setRefetch={setRefetch} categoreyId={categoreyId} item={item} />
            </div>
          ))}
          {productsCategorey?.length <= 3 &&
            productsCategorey?.map((item) => (
              <div key={item._id}>
                <SliderCard refetch={refetch} setRefetch={setRefetch} categoreyId={categoreyId} item={item} />
              </div>
            ))}
        </Slider>
      </Box>
    </Container>
  );
};

export default ProductSlider;
