/* eslint-disable import/no-unresolved */
/* eslint-disable dot-notation */
import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { API_IMG_URL } from 'src/Redux/api/http-common';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { searchProduct } from 'src/Redux/slice/products';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, decreaseFromCart, getCartTotal, removeFromCart } from 'src/Redux/slice/cartSlice';
import { navbarStyles } from './style';
import Logo from '../../assets/logo.webp';
import Image from '../../assets/3 (1).png';

// eslint-disable-next-line react/prop-types

function limitLetters(inputString, limit) {
  // Ensure the limit is within the range of the input string length
  limit = Math.min(limit, inputString.length);

  // Extract the first 'limit' letters from the input string
  const limitedString = inputString.slice(0, limit);

  return limitedString;
}
const Navbar = ({ toggle, isFixed }) => {
  const [state, setState] = React.useState({ right: false });
  const dispatch = useDispatch();
  const [quantity, setQuantity] = React.useState(1);
  const [refetch, setRefetch] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState('');
  // const [searchProduct, setSearchProduct] = React.useState([]);

  const navigate = useNavigate();
  const cartSlice = useSelector((s) => s?.cartSlice);
  const products = useSelector((s) => s?.products?.searchProduct?.data);
  const productssd = useSelector((s) => s?.products);

  console.log('products', productssd);
  const location = useLocation();
  console.log('location', location);

  const handleChangeSearch = (e) => {
    setSearchKey(e.target.value);
  };

  const getSearchProducts = async () => {
    try {
      const res = await dispatch(searchProduct(searchKey));
      console.log('RES', res);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDrawer = (anchor, open, item) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (item.cart) {
      navigate('/cart');
    }

    setState({ ...state, [anchor]: open });
  };
  const handleIncrease = (item) => {
    setQuantity(quantity + 1);
    dispatch(addToCart(item));
  };
  const handleDecrease = (item) => {
    setQuantity(quantity - 1);
    dispatch(decreaseFromCart(item));
  };
  useEffect(() => {
    dispatch(getCartTotal());
  }, [dispatch, quantity, refetch]);

  useEffect(() => {
    getSearchProducts();
  }, [searchKey, dispatch]);
  return (
    <>
      <Box
        alignItems={'center'}
        paddingX={4}
        paddingY={2}
        sx={{ backgroundColor: 'white' }}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Link to={'/'}>
          <img width={'100%'} src={Logo} alt="" />
        </Link>
        <Stack position={'relative'} justifyContent={'center'} alignItems={'center'} width={'100%'} direction={'row'}>
          {location.pathname !== '/pages/category/product' && (
            <TextField
              placeholder="Search The Store"
              sx={{
                width: '50%',
                backgroundColor: '#FAFAFA',
                border: 'none',
                outline: 'none',
              }}
              onChange={handleChangeSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          {products?.length !== 0 && (
            <Box className="searchNav">
              <Grid container>
                {products?.map((item) => (
                  <Grid item xs={12} key={item?._id}>
                    <Link
                      to={{
                        pathname: '/pages/category/product',
                      }}
                      onClick={() => setSearchKey('')}
                      state={{
                        id: item._id,
                        categoreyId: item.categoryId,
                      }}
                    >
                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-evenly'} gap={4}>
                        <Typography>{limitLetters(item?.title, 25)}</Typography>
                        <Typography>{item?.price}</Typography>
                        <img
                          width={'100px'}
                          height={'100px'}
                          style={{
                            objectFit: 'contain',
                          }}
                          src={API_IMG_URL + item.coverimage}
                          alt=""
                        />
                      </Box>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {/* <Typography>Customer Service +92 308 1 487 487</Typography> */}
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <Stack alignItems={'center'}>
            <IconButton>
              <FavoriteBorderIcon />
            </IconButton>
            <Typography textAlign={'center'} textTransform={'uppercase'} fontSize={'12px'}>
              Wish
              <br />
              Lists
            </Typography>
          </Stack>
          <Stack alignItems={'center'} onClick={toggleDrawer('right', true, { cart: false })}>
            <IconButton>
              <ShoppingCartOutlinedIcon />
            </IconButton>
            <Typography textTransform={'uppercase'} fontSize={'12px'}>
              Cart
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <>
        <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false, { cart: false })}>
          <Stack padding={3} width={400} spacing={2}>
            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
              <Typography fontWeight={700}>Shopping Cart</Typography>
              <IconButton onClick={toggleDrawer('right', false, { cart: false })}>x</IconButton>
            </Stack>
            <Typography fontSize={'12px'}>{cartSlice?.cartItems?.length} Items</Typography>
            {cartSlice?.cartItems?.length > 0 ? (
              <>
                {cartSlice?.cartItems?.map((item) => (
                  <Grid container key={item.id}>
                    <Grid item xs={4}>
                      <img src={API_IMG_URL + item.coverimage} alt="" />
                    </Grid>
                    <Grid item xs={6}>
                      <Stack spacing={1}>
                        <Typography>{item.title}</Typography>
                        <Typography>{item.newPrice}</Typography>
                        <Box
                          justifyContent="space-around"
                          sx={{
                            height: '35px',
                            backgroundColor: 'rgba(0, 0, 0, 0.15)',
                          }}
                          width="70%"
                          alignItems="center"
                          display="flex"
                        >
                          <IconButton disableRipple onClick={() => handleDecrease(item)}>
                            -
                          </IconButton>

                          <Typography>{item.cartQuantity}</Typography>
                          <IconButton disableRipple onClick={() => handleIncrease(item)}>
                            +
                          </IconButton>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => {
                          dispatch(removeFromCart(item));
                          setRefetch(!refetch);
                        }}
                      >
                        x
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : (
              <Typography textAlign={'center'} fontWeight={700}>
                Your Cart is Empty
              </Typography>
            )}

            <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
              <Typography fontWeight={700}>Total:</Typography>
              <Typography fontWeight={700}>
                RS. {cartSlice?.cartItems?.length > 0 ? cartSlice?.cartTotalAmount : '00.00'}
              </Typography>
            </Stack>
            {cartSlice?.cartItems?.length !== 0 && (
              <Button variant="addToCart" onClick={() => navigate('/proceed')}>
                PROCEED TO CHECKOUT
              </Button>
            )}

            <Button variant="addToCartOpposite" onClick={toggleDrawer('right', false, { cart: true })}>
              VIEW CART
            </Button>
          </Stack>
        </Drawer>
      </>
    </>
  );
};

export default Navbar;
