/* eslint-disable import/no-unresolved */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import categoryServices from '../api/categoryServices';

export const initialState = {
  data: [],
  loading: 'idle',
  error: null,
  message: null,
};

export const createCategory = createAsyncThunk('category/createCategory', async (data) => {
  try {
    const res = await categoryServices.createCetgory(data);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const createSubCategory = createAsyncThunk('category/createSubCategory', async (data) => {
  try {
    const res = await categoryServices.createSubCetgory(data);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getAllCategory = createAsyncThunk('category/getAllCategory', async () => {
  try {
    const res = await categoryServices.getAllCategory();

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCategory.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createCategory.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(createCategory.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(getAllCategory.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getAllCategory.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.data = action.payload;
    });
    builder.addCase(getAllCategory.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(createSubCategory.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createSubCategory.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(createSubCategory.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });
  },
});

export default categorySlice.reducer;
