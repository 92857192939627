import { Box, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NavLinks = () => {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const category = useSelector((s) => s.category?.data?.data);
  console.log('category', category);

  // Reorder categories to have "All Products" at the beginning
  const reorderedCategories = category && [...category];
  const allProductsIndex = reorderedCategories?.findIndex((cat) => cat?.title === 'All Products');
  if (allProductsIndex !== -1) {
    const allProductsCategory = reorderedCategories?.splice(allProductsIndex, 1)[0];
    reorderedCategories?.unshift(allProductsCategory);
  }

  const handleCategoryHover = (categoryId) => {
    setHoveredCategory(categoryId);
  };

  const handleCategoryLeave = () => {
    setHoveredCategory(null);
  };
  return (
    <Stack sx={{ backgroundColor: 'white' }} paddingX={4} direction={'row'} spacing={4}>
      {reorderedCategories?.map((category) => (
        <Box
          key={category.id}
          onMouseEnter={() => handleCategoryHover(category.id)}
          onMouseLeave={handleCategoryLeave}
          sx={{
            cursor: 'pointer',
            position: 'relative',
            zIndex: 10,
            '&:hover': {
              color: 'rgb(219, 27, 27)',
            },
          }}
        >
          <Stack paddingY={4} spacing={1} direction={'row'} alignItems={'center'}>
            {category.title === 'All Products' || category.title === 'Truck Art' || category.title === 'Rugs' ? (
              <Link
                style={{
                  color: 'gray',
                }}
                to={{
                  pathname:
                    category.title.split(' ').length === 2
                      ? `pages/${category.title.split(' ')[0]}-${category.title.split(' ')[1]}`
                      : `pages/${category.title.split(' ')[0]}`,
                  state: {
                    id: category.id, // Pass the id in the state object
                  },
                }}
                state={{
                  id: category.id, // Pass the id in the state object
                }}
                variant="subtitle2"
                color="textSecondary"
              >
                {category.title}
              </Link>
            ) : (
              <Typography fontSize={'14px'} fontWeight={400}>
                {category.title}
              </Typography>
            )}

            {category.title === 'All Products' || category.title === 'Truck Art' || category.title === 'Rugs' ? null : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </Stack>
          {hoveredCategory === category.id && (
            <>
              {category.title === 'All Products' ||
              category.title === 'Truck Art' ||
              category.title === 'Rugs' ? null : category.title === 'Home Decor' ? (
                <Grid
                  container
                  spacing={4}
                  sx={{
                    position: 'absolute',
                    top: 95,
                    left: -80,
                    width: 990,
                    backgroundColor: 'white',
                    borderTop: '1px solid #d3d3d3',
                  }}
                >
                  {category.subcategories.map((subcategory) => (
                    <Grid item xs={6} sm={3} key={subcategory.id}>
                      <Link
                        style={{
                          color: 'gray',
                        }}
                        to={{
                          pathname:
                            subcategory.title.split(' ').length === 2
                              ? `pages/${subcategory.title.split(' ')[0]}-${subcategory.title.split(' ')[1]}`
                              : `pages/${subcategory.title.split(' ')[0]}`,
                          state: {
                            id: subcategory.id,
                          },
                        }}
                        state={{
                          id: subcategory.id,
                        }}
                        variant="subtitle2"
                        color="textSecondary"
                      >
                        {subcategory.title}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Stack
                  spacing={3}
                  sx={{
                    position: 'absolute',
                    top: 65,
                    backgroundColor: 'white',
                    padding: 2,
                    width: 250,
                    borderTop: '1px solid #d3d3d3',
                  }}
                >
                  {category?.subcategories?.map((subcategory) => (
                    <Link
                      key={subcategory.id}
                      style={{
                        color: 'gray',
                      }}
                      to={{
                        pathname:
                          subcategory.title.split(' ').length === 2
                            ? `pages/${subcategory.title.split(' ')[0]}-${subcategory.title.split(' ')[1]}`
                            : `pages/${subcategory.title.split(' ')[0]}`,
                        state: {
                          id: subcategory.id,
                        },
                      }}
                      state={{
                        id: subcategory.id,
                      }}
                      variant="subtitle2"
                      color="textSecondary"
                    >
                      {subcategory.title}
                    </Link>
                  ))}
                </Stack>
              )}
            </>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default NavLinks;
