/* eslint-disable import/no-unresolved */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import orderServices from '../api/orderServices';

export const initialState = {
  data: [],
  loading: 'idle',
  error: null,
  message: null,
};

export const createOrder = createAsyncThunk('order/createOrder', async (data) => {
  try {
    const res = await orderServices.createOrder(data);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const getOrder = createAsyncThunk('order/getOrder', async () => {
  try {
    const res = await orderServices.getOrders();

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const editOrders = createAsyncThunk('order/editOrders', async (id) => {
  try {
    const res = await orderServices.editOrders(id);

    return res.data;
  } catch (error) {
    toast.error(error.response.data.message);
    return error.message;
  }
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(createOrder.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(getOrder.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.data = action.payload;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });

    builder.addCase(editOrders.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(editOrders.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(editOrders.rejected, (state, action) => {
      state.loading = 'rejected';
      state.message = action.payload;
    });
  },
});

export default orderSlice.reducer;
